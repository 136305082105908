import React, { useState, useRef} from 'react'
import { graphql, navigate } from 'gatsby'
import SEO from '../components/seo'
import '../components/CSS/form.css'
import * as S from '../components/Form/styled'
import useTranslations from '../components/useTranslations'
import DocumentDownload from '../components/LandingPage/documentDownload'
import { FaFileUpload } from 'react-icons/fa'
import Recaptcha from "react-google-recaptcha";

const FormPage = props => {
  const post = props.data.markdownRemark
  const { retmonTitle, formlang } = useTranslations()

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const [validated, setValidated] = useState(false)
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)

    if (form.checkValidity() === true)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'Retmon_Contact_v2',
          ...state,
        }),
      }).then(() => navigate(form.getAttribute('action')))
  }

  function toggleButton() {
    setPanel(false)
  }
  function toggleButtonNO() {
    setPanel(true)
  }

  function EnergyYes() {
    if (!energy) setEnergy(true)
    else setEnergy(false)
  }

  const onChangeHandlerFile = e => {
    if (e.target.files[0].size > 200000) {
      alert(formlang.infoFile)
    }
  }

  const [panel, setPanel] = useState(true)
  const [energy, setEnergy] = useState(true)

  const RECAPTCHA_KEY = "6LdMFrQZAAAAAOqEraWCxky1L0kPwZtfqxHk0dS9"
   //const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
    
  const handleRecaptcha = e => {
    setState({ ...state, "g-recaptcha-response": e.target.value });
  };

  const recaptcha = useRef(null);

  const {
    namesurname,
    company,
    phone,
    location,
    mail,
    text_sensors,
    text_bolgeler,
    text_uzaklik,
    area_m2,
    text_others,
    text_energy_analysis,
    campaign,
  } = useState({})

  // Conditional Fields
  // const nofilterClassEnergy = energy === false ? "nofilter" : null
  // const nofilterClassPanel = panel === false ? "nofilter" : null

  const distance_placeholder = formlang.room_distance_place

  // Error Borders
  //  const formError = `${setValidated === true || state  ?  '' : 'errorBorders'}`

  const RequiredField = (
    <span className="requiredField">* {formlang.requiredField}</span>
  )

  return (
    <>

      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={post.frontmatter.description}
      />
      <S.Margin />
      <S.Header>{post.frontmatter.title}</S.Header>
      <form
        netlify
        validated={validated}
        onSubmit={handleSubmit}
        name="Retmon_Contact_v2"
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="Retmon_Contact_v2" />
        <S.Label>
          {formlang.companyInfos} {RequiredField}{' '}
        </S.Label>
        <S.FlexRow>
          <S.Input
            type="text"
            required
            name="İsim Soyisim"
            value={namesurname}
            placeholder={formlang.name}
            onChange={handleChange}
          ></S.Input>
          <S.Input
            type="mail"
            required
            name="E-Posta"
            value={mail}
            placeholder={formlang.mail}
            onChange={handleChange}
          ></S.Input>
        </S.FlexRow>
        <S.FlexRow>
          <S.Input
            type="number"
            required
            name="Telefon"
            value={phone}
            placeholder={formlang.phone}
            onChange={handleChange}
          ></S.Input>
          <S.Input
            type="text"
            required
            name="Şirket"
            value={company}
            placeholder={formlang.company}
            onChange={handleChange}
          ></S.Input>
          <S.Input
            type="text"
            required
            name="Lokasyon"
            value={location}
            placeholder={formlang.location}
            onChange={handleChange}
          ></S.Input>
        </S.FlexRow>
        <S.Label>{formlang.technicalInfos} </S.Label>
        <S.FormBorder>
          {/* <b>{formlang.ethernet}</b>
          <S.FormSelectWrapper >
            <S.FormInputRadio id="type_Eth" type="radio" name="Ethernet Network" value='Evet' onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_Eth">{formlang.yes}</S.LabelRadio>
            <S.FormInputRadio id="type_No" type="radio" name="Ethernet Network" value='Hayır' onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio
              className='negative'
              for="type_No">{formlang.no}</S.LabelRadio> 
          </S.FormSelectWrapper>
          <b>{formlang.total_tag_number}</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="type_TAG_0-20" type="radio" name="İzlemek istediğiniz toplam TAG (Nokta) sayısı?" value="0-20" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_TAG_0-20">0-20</S.LabelRadio>

            <S.FormInputRadio multiple id="type_TAG_21-50" type="radio" name="İzlemek istediğiniz toplam TAG (Nokta) sayısı?" value="21-50" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_TAG_21-50">21-50</S.LabelRadio>

            <S.FormInputRadio multiple id="type_TAG_50-100" type="radio" name="İzlemek istediğiniz toplam TAG (Nokta) sayısı?" value="50-100+" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_TAG_50-100">50-100+</S.LabelRadio>
          </S.FormSelectWrapper>
          <b>{formlang.alarm_notifications}</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="type_NotifyType_SMS" type="checkbox" name="Alarm bildirimlerini nasıl almak istersiniz?[]" value="SMS" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_NotifyType_SMS">{formlang.alarm_notifications_sms}</S.LabelRadio>

            <S.FormInputRadio multiple id="type_NotifyType_Mail" type="checkbox" name="Alarm bildirimlerini nasıl almak istersiniz?[]" value="E-Posta" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_NotifyType_Mail">{formlang.alarm_notifications_email}</S.LabelRadio>

            <S.FormInputRadio multiple id="type_NotifyType_SirenSound" type="checkbox" name="Alarm bildirimlerini nasıl almak istersiniz?[]" value="Siren (Ses)" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_NotifyType_SirenSound">{formlang.alarm_notifications_siren_sound}</S.LabelRadio>

            <S.FormInputRadio multiple id="type_NotifyType_SirenSoundLight" type="checkbox" name="Alarm bildirimlerini nasıl almak istersiniz?[]" value="Siren (Ses ve Işık)" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_NotifyType_SirenSoundLight">{formlang.alarm_notifications_siren_sound_light}</S.LabelRadio>

            <S.FormInputRadio multiple id="type_NotifyType_CallNotify" type="checkbox" name="Alarm bildirimlerini nasıl almak istersiniz?[]" value="Telefon ile Uyarı Bildirim/Çağrı" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_NotifyType_CallNotify">{formlang.alarm_notifications_phone_notify}</S.LabelRadio>

            <S.FormInputRadio multiple id="type_NotifyType_Call" type="checkbox" name="Alarm bildirimlerini nasıl almak istersiniz?[]" value="Telefon Araması" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_NotifyType_Call">{formlang.alarm_notifications_phone_call} *</S.LabelRadio>
          </S.FormSelectWrapper>
          <S.FormText>* {formlang.extra}</S.FormText>
          <br /><br />
          <b>{formlang.threeg_modem} * </b>
          <S.FormSelectWrapper >
            <S.FormInputRadio id="type_3G_YES" type="radio" name="Terminalin 3G bağlantısı için 3G modem ister misiniz?" value="Yes" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="type_3G_YES">{formlang.yes}</S.LabelRadio>
            <S.FormInputRadio id="type_3G_NO" type="radio" name="Terminalin 3G bağlantısı için 3G modem ister misiniz?" value="No" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio
              className='negative'
              for="type_3G_NO">{formlang.no}</S.LabelRadio>
          </S.FormSelectWrapper>
          <S.FormText>* {formlang.threeg_mode_info}</S.FormText>
          <br /><br />*/}
          <b>{formlang.select_sensors}</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio
              multiple
              id="type_param_sicaklik"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Sıcaklık"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_sicaklik">
              {formlang.temperature}
            </S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_nem"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Nem"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_nem">
              {formlang.humidity}
            </S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_hareket"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Hareket"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_hareket">
              {formlang.motion}
            </S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_sukacak"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Su Kaçak"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_sukacak">
              {formlang.water_leak}{' '}
            </S.LabelRadio>
            <S.FormInputRadio
              multiple
              id="type_param_duman"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Duman"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_duman">{formlang.smoke}</S.LabelRadio>
            <S.FormInputRadio
              multiple
              id="type_param_combo"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Combo (Sıcaklık ve Nem)"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_combo">{formlang.combo}</S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_havakalite"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Hava Kalite"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_havakalite">
              {formlang.air}
            </S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_havaakis"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Hava Akışı"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_havaakis">
              {formlang.air_flow}
            </S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_darbe"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Darbe"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_darbe">
              {formlang.impact}
            </S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_isik"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Işık"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_isik">{formlang.light}</S.LabelRadio>

            <S.FormInputRadio
              multiple
              id="type_param_kapi"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Kapı"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_kapi">
              {formlang.door_sensor}
            </S.LabelRadio>
            <S.FormInputRadio
              multiple
              id="type_param_enerji"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="Enerji Analizi"
              onClick={EnergyYes}
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_enerji">
              {formlang.energy}
            </S.LabelRadio>
            <S.FormInputRadio
              multiple
              id="type_param_IPcam"
              type="checkbox"
              name="İzlemek istediğiniz parametre yada sensörleri seçiniz[]"
              value="IP Kamera"
              onChange={handleChange}
            ></S.FormInputRadio>
            <S.LabelRadio for="type_param_IPcam">
              {formlang.ip_cam}
            </S.LabelRadio>
          </S.FormSelectWrapper>
          <br />

          {/* <b>{formlang.energy_parameter}</b>
          <S.FormSelectWrapper>
            <S.TextArea
              name="Hangi enerji parametrelerini takip etmek istiyorsunuz?"
              value={text_energy_analysis}
              spellcheck="false"
              placeholder={formlang.energy_parameter_place}
            ></S.TextArea>
          </S.FormSelectWrapper>
        
          <br />
          <b>{formlang.sensor_distance} {RequiredField}   </b>
          <S.FormSelectWrapper>
            <S.TextArea
              required
              name="Sensörlerin adetlerini ve birbirlerine olan uzaklıkları"
              value={text_sensors}
              spellcheck="false"
              placeholder={formlang.sensor_distance_place}
            ></S.TextArea>
          </S.FormSelectWrapper>
          <br /><br />
          <b>{formlang.number_of_regions} {RequiredField} </b>
          <S.FormSelectWrapper>
            <S.TextArea
              required
              name="İzlemek istediğiniz bölgelerin sayısı/isimleri nelerdir?"
              value={text_bolgeler}
              spellcheck="false"
            ></S.TextArea>
          </S.FormSelectWrapper>
          <br /><br />
          <b>{formlang.room_distance} {RequiredField} </b>
          <S.FormSelectWrapper>
            <S.TextArea
              required
              name="İzlenmek istenilen oda yada alanların birbirlerine olan uzaklıklarını yazınız"
              value={text_uzaklik}
              spellcheck="false"
              placeholder={`${distance_placeholder}`}
            ></S.TextArea>
          </S.FormSelectWrapper>
          <br />
          <b>{formlang.surface_area}</b>
          <S.FormSelectWrapper width="8rem" >
            <S.InputSpec
              style={{ marginLeft: '0' }}
              type="text" name="İzlenmek istenilen oda yada alanların yüz ölçümü kaçtır?" value={area_m2} placeholder="m2" onChange={handleChange}></S.InputSpec>
          </S.FormSelectWrapper>
          <br />
          <b>{formlang.mini_UPS}</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="ups_setup_yes" type="radio" name="Terminale özel mini UPS ister misiniz?" value="Evet" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="ups_setup_yes">{formlang.yes}</S.LabelRadio>
            <S.FormInputRadio multiple id="ups_setup_no" type="radio" name="Terminale özel mini UPS ister misiniz?" value="Hayır" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio className='negative' for="ups_setup_no">{formlang.no}</S.LabelRadio>
          </S.FormSelectWrapper>
          <S.FormText></S.FormText>
          <br />
          <b>{formlang.installation} *</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="special_setup_yes" type="radio" name="Kurulum ve Devreye Alım istiyor musunuz?" value="Evet" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="special_setup_yes">{formlang.yes}</S.LabelRadio>
            <S.FormInputRadio multiple id="special_setup_no" type="radio" name="Kurulum ve Devreye Alım istiyor musunuz?" value="Hayır" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio className='negative' for="special_setup_no">{formlang.no}</S.LabelRadio>
          </S.FormSelectWrapper>
          <S.FormText>* {formlang.installation_info}</S.FormText>
          <br />   <br />
          <b>{formlang.installation_server}</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="dcim_server_yes" type="radio" name="Retmon DCIM server kurulumu istiyor musunuz?" value="Evet" onClick={toggleButton} onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="dcim_server_yes">{formlang.yes}</S.LabelRadio>
            <S.FormInputRadio multiple id="dcim_server_no" type="radio" name="Retmon DCIM server kurulumu istiyor musunuz?" value="Hayır" onClick={toggleButtonNO} onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio className='negative' for="dcim_server_no">{formlang.no}</S.LabelRadio>
          </S.FormSelectWrapper>
          <br />
          {/* <S.Filter
            className={nofilterClassPanel}>
          <b>{formlang.server_preference}</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="dcim_server_setup_virtual" type="radio" name="Retmon DCIM server kurulum tercihiniz" value="Retmon DCIM Server Sanal Sunucu Kalıbı" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="dcim_server_setup_virtual">{formlang.server_preference_dcim}</S.LabelRadio>
            <S.FormInputRadio multiple id="dcim_server_setup_local" type="radio" name="Retmon DCIM server kurulum tercihiniz" value="Fiziksel Sunucu Kurulumu" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio className='negative' for="dcim_server_setup_local">{formlang.server_preference_local}</S.LabelRadio>
          </S.FormSelectWrapper>
          <br />
          <b>{formlang.keep_data}*</b>
          <S.FormSelectWrapper>
            <S.FormInputRadio multiple id="data_collect_time_1_month" type="radio" name="Sistem içerisindeki verileri ne kadar süre ile saklamak istersiniz?" value="1 Ay" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="data_collect_time_1_month">{formlang.keep_data_one_month}</S.LabelRadio>
            <S.FormInputRadio multiple id="data_collect_time_2_years" type="radio" name="Sistem içerisindeki verileri ne kadar süre ile saklamak istersiniz?" value="2 Ay" onChange={handleChange}></S.FormInputRadio>
            <S.LabelRadio for="data_collect_time_2_years">{formlang.keep_data_two_years}</S.LabelRadio>
          </S.FormSelectWrapper>
          <S.FormText>*{formlang.keep_data_info} </S.FormText>
          <br /><br />
          <b>{formlang.labelFile} *</b>
          <S.FormSelectWrapper>
            <div>
              <label className='FileLabel' for="file">
                <FaFileUpload /> {formlang.selectFile}
              </label>
              <input
                id="file"
                style={{ visibility: 'hidden' }}
                type="file"
                onChange={onChangeHandlerFile}
              />
            </div>
            <br /><br />
          </S.FormSelectWrapper>
          <S.FormText>* {formlang.infoFile}</S.FormText>
          <br /><br /> */}
          <b>{formlang.mention}</b>
          <S.FormSelectWrapper>
            <S.TextArea
              name="Diğer Bilgiler"
              value={text_others}
              spellcheck="false"
            ></S.TextArea>
          </S.FormSelectWrapper>
          {/*  <br />
          <b>{formlang.campaignCode}</b>
         <S.FormSelectWrapper>
            <S.Input
              style={{ width: '12rem' }}
              type="text"
              className={`${state.KampanyaKodu === 'RTMLDN0120' ? 'validatedInput' : 'UnvalidatedInput'}`}
              name="KampanyaKodu" value={campaign} defaultValue='' onChange={handleChange}></S.Input>
          </S.FormSelectWrapper> */}
        </S.FormBorder>
        {/* <div data-netlify-recaptcha="true"/> */}
         <Recaptcha
            ref={recaptcha}
            sitekey={RECAPTCHA_KEY}
            onChange={handleRecaptcha}

          /> 
        <S.RetmonCTAButton right width="12rem" type="submit">
          {validated === true ? <span>{formlang.sending}</span> : formlang.send}
        </S.RetmonCTAButton>
      </form>
    </>
  )
}

export const query = graphql`
  query Form($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        page
      }
      html
    }
  }
`

export default FormPage
