import styled from 'styled-components';
import media from 'styled-media-query';

export const Row = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-content: flex-start;
-ms-flex-line-pack: start;
align-content: flex-start;
-webkit-flex-wrap: ${props => props.wrap ? "wrap;" : "none"};
-ms-flex-wrap: ${props => props.wrap ? "wrap;" : "none"};
flex-wrap: ${props => props.wrap ? "wrap;" : "none"};
-webkit-box-pack:  ${props => props.justify || "center"};
-webkit-justify-content:  ${props => props.justify || "center"};
-ms-flex-pack:  ${props => props.justify || "center"};
justify-content: ${props => props.justify || "center"};
align-items: ${props => props.align || "center"};
padding-top: 5rem;
${media.lessThan('medium')`
padding-top: 0rem;
`}
`;



export const FeedbackColumn = styled.div`
width: ${props => props.width || "auto"};
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin: 0.5rem;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
${media.greaterThan('large')`
width: ${props => props.width || "auto"};
`}
${media.lessThan('medium')`
width: auto;
`}
`;


export const Header = styled.h1`
font-weight: 400;
font-style: normal;
font-size: 32px;
margin-bottom:4rem;
`;

export const Header2 = styled.h2`
font-weight: bold;
font-style: normal;
font-size: 32px;
margin-bottom:4rem;
`;

export const HeaderBold = styled.h1`
font-weight: 700;
font-style: normal;
font-size: 32px;
`;
export const SubHeader = styled.h2`
font-weight: 700;
font-size: ${props => props.Size || "18px"};
color:  ${props => props.Color || "black"};
`

export const Text = styled.p`
margin-top: ${props => props.high ? "3rem" : "0rem"};
font-weight: 400;
font-style: normal;
font-size: 16px;
`;


export const AniStick = styled.div`
height: 15px;
background: ${props => props.C || "var(--red)"};
width: ${props => props.W || "12rem"};
top: ${props => props.T || "12rem"};
margin-top:1rem;
top: 10rem;
z-index: -1;
transition: all .6s ease-in;
@media(max-width: 768px) {
display:none
}

`
export const RetmonCTAButton = styled.button`
background: ${props => props.Color || "black"};
color:white;
padding:9px;
outline: none;
border: none;
margin-top:1rem;
cursor:pointer;
float:${props => props.right ? "right" : "unset"}; 
width: ${props => props.width || "-webkit-fill-available;"}; 
transition: all .3s ease; 
&:hover{
background: #ec0753;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: var(--redGR);
  } 
`


export const Column = styled.div`
width: ${props => props.width || "auto"};
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin: 0.5rem;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
text-align: ${props => props.text || "left"};
${media.greaterThan('large')`
width: ${props => props.width || "auto"};
`}
`;

export const Span = styled.span`
color: ${props => props.Color || "var(--red)"};
`

export const FlexCard = styled.div`
margin:2rem;  
`
export const FlexColumn = styled.div`
width: ${props => props.width || "auto"};
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
padding-left: 1rem;
padding-right: 1rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin: 0.5rem;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
`
export const FlexRow = styled.div`
display:flex;
align-items: center;
justify-content: center;

${media.lessThan('medium')`
flex-wrap: wrap;
`}
`

export const FeedbackWrapper = styled.div`
${media.lessThan('medium')`
display: none;
`}
`


export const Margin = styled.div`
margin-top:5rem;
margin-bottom:5rem;
${media.lessThan('medium')`
margin: 0;
`}
`;


export const Input = styled.input`
padding: 1rem;
background: #e6e3e345;
outline: none;
border: none;
margin-top : 1rem;
margin-bottom : 1rem;
font-size: 14px;
width:-webkit-fill-available;
width: 100%;
transition: all .3s ease; 
&:hover{
background: #e6e3e3a3;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: #e6e3e345;
  } 
`;


export const Select = styled.select`
padding: 1rem;
background: #e6e3e345;
outline: none;
border: none;
margin-top : 1rem;
margin-bottom : 1rem;
font-size: 14px;
width:-webkit-fill-available;
transition: all .3s ease; 
&:hover{
background: #e6e3e3a3;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: #e6e3e345;
  } 
`;


export const InputSpec = styled.input`
padding: 1rem;
wdith:12rem;
background: #e6e3e345;
outline: none;
border: none;
margin: 1rem;
font-size: 14px;
width:-webkit-fill-available;
transition: all .3s ease; 
&:hover{
background: #e6e3e3a3;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: #e6e3e345;
  } 
`;

export const Label = styled.div`
color: gray;
margin-top:1rem;
    padding: 1rem;
    border: 1px solid #a09a9a80 ;
    border-radius: 5px;
    font-size: var(--textBase);
    padding: 4px;
    text-align: ${props => props.textAlign || 'center'};;
`;
export const LabelRequired = styled.div`
color: red;
margin-top:1rem;
margin-bottom:1rem;
    padding: 1rem;
    border: 1px solid var(--red) ;
    border-radius: 5px;
    font-size: 13px;
    padding: 6px;
    text-align: center;
`;


export const FormInputRadio = styled.input`
  position: absolute;
  left: -9999em;
  &:focus {
    border: 2px solid #da3654;
    outline: 0;
    color: #fff;
    box-shadow: rgb(183, 188, 189) 0px 3px 10px;
    filter: opacity(1);
  }
  &:active {
    border: 2px solid #da3654;
    outline: 0;
    color: #fff;
    box-shadow: rgb(183, 188, 189) 0px 3px 10px;
    filter: opacity(1);
  }
  
`
export const FormSelectWrapper = styled.div`
flex-wrap: wrap;
width: ${props => props.width || "auto"};
display: flex;
margin-top:'1rem'
`

export const LabelRadio = styled.label`
border: 1px solid #00000026;
outline: none;
filter: opacity(0.5);
    color: black;
    margin:1rem;
    display: block;
    cursor: pointer;
    background: white;
    padding: 0.775rem 1.03333rem;
    border-radius: 0.5rem;
    
    transition: box-shadow 0.3s cubic-bezier(0, 0.89, 0.44, 1) 0s, transform 0.15s cubic-bezier(0, 0.89, 0.44, 1) 0s, background 0.15s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    &:hover{
      background: #da3654;
      color: #fff;
      transition: all .3s ease; 
      filter: opacity(1);
        }
   &:active{ 
     filter: opacity(1);
       }
`;


export const FormBorder = styled.div`
border: 1px solid #a09a9a80;
margin-bottom: 1rem;
padding:2rem;
font-size:14px;
border-radius: 5px;
`;

export const FormText = styled.text`
font-size:12px
`;


export const TextArea = styled.textarea`
width:100%;
padding:1rem;
font-family: inherit;
border: 1px solid #c1c1c1;
margin-top:1rem;
height: 15rem;
outline: none;
font-size:14px;
`;

export const Filter = styled.div`
filter: opacity(0.3);
cursor: default;
pointer-events: none!important;
transition: all .2s ease-in;

`;


export const SVG = styled.img`
width: ${props => props.Size || "auto"};
filter: ${props => props.gray ? "grayscale(1)" : "none"};
margin-right:${props => props.right || "0"};
margin:auto;
`